<template>
  <div
    class="flex flex-col mx-auto text-left"
    v-if="isAuthenticated === 'unauthorized'"
  >
    <form @submit.prevent="login(email, password)" class="login-form">
      <h1 class="mb-4 text-3xl">Login</h1>
      <input
        type="text"
        placeholder="Email"
        class="block w-full px-4 py-2 mb-2 form-input"
        v-model="email"
      />
      <input
        type="password"
        placeholder="Password"
        class="block w-full px-4 py-2 mb-2 form-input"
        v-model="password"
      />
      <input
        type="submit"
        value="Login"
        class="px-4 py-2 mr-2 text-lg text-gray-100 bg-green-500 rounded hover:opacity-75"
      />
      <p class="mt-4">
        Need an account?
        <router-link to="/register" class="font-semibold text-white"
          >Register Here</router-link
        >
      </p>
    </form>
  </div>
</template>

<script>
// @ is an alias to /src
import { ref } from "vue";
import { isAuthenticated, login } from "../firebase.js";

export default {
  setup() {
    const email = ref("");
    const password = ref("");

    return {
      email,
      password,
      isAuthenticated,
      login,
    };
  },
};
</script>
